import { useContext, useState } from "react";
import { StoreContext } from "../../../stores/StoreLoader";
import useSWR, { mutate } from "swr";
import WatsonApi from "../../../backends/WatsonApi";
import {
    IReactiveEventContentItem,
    IReactiveNewsContentItem,
    ReactiveContentItem,
    SchoolFeedContentItem
} from "../../../stores/SchoolFeedStore";

export function useFirstPageSchoolFeedContent(
    baseFilterParams: { [k: string]: any } | null, // valid content item filtering parameters
) {
    const { userStore } = useContext(StoreContext);
    const [isMoreContent, setIsMoreContent] = useState(false);
    const [currentView, setCurrentView] = useState('news');

    const fetcher = async () => {
        const q = {
            ...baseFilterParams,
            expand: "organization",
            page: 1,
        };

        if (!userStore.editor) {
            q["published"] = true;
        }

        const client = await WatsonApi();


        const news_response = await client.apis["content-items"].content_items_list({
            ...q,
            page_size: 15,
            is_news: true,
            is_media: true,
            is_file: true,
            is_coursework: true,
        });
        const events_response = await client.apis["content-items"].content_items_list({
            ...q,
            is_event: true,
            page_size: 15,
            event_end: new Date().toISOString().split("T")[0], // Current date for event end
            ordering: "json_data__event__start",
        });
        let newsReturnData: IReactiveNewsContentItem[] = [];
        let eventsReturnData: IReactiveEventContentItem[] = [];

        if (news_response) {
            const newsResponseData = JSON.parse(news_response.data);
            setIsMoreContent(!!newsResponseData.links.next);
            if (newsResponseData.data) {
                newsReturnData = newsResponseData.data.map((item: ReactiveContentItem) => {
                    return new ReactiveContentItem(item) as IReactiveNewsContentItem;
                }) as IReactiveNewsContentItem[];
            }
        }
        if (events_response) {
            const eventsResponseData = JSON.parse(events_response.data);
            setIsMoreContent(!!eventsResponseData.links.next);
            if (eventsResponseData.data) {
                eventsReturnData = eventsResponseData.data.map((item: ReactiveContentItem) => {
                    return new ReactiveContentItem(item) as IReactiveEventContentItem;
                }) as IReactiveEventContentItem[];
            }
        }
        if (newsReturnData.length === 0) {
            setCurrentView('events');
        }
        if (eventsReturnData.length === 0) {
            setCurrentView('news');
        }

        return {
            data: {"news": newsReturnData, "events": eventsReturnData},
        };
    };

    const key = baseFilterParams ? ["content-items", JSON.stringify(baseFilterParams), currentView, userStore.editor] : null;
    const { data, error } = useSWR(key, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
    });

    const isLoading = !data && !error;

    return {
        data: data ? data.data : {news: [], events: []},
        isMoreContent,
        isLoading,
        error,
        mutate: () => mutate(key),  // <-- Return the mutate function for re-fetching
        currentView,
        setCurrentView,
    };
}
